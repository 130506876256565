// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { v1 as uuid } from 'uuid'
import classnames from 'classnames'
import OutsideClick from 'react-onclickout'
import { compose } from 'redux'

import Button from '../../Button'
import EditManagersPopup from '../../modals/UserPopup/UserPopup'
import MailReplyForm from '../../Mail/MailReplyForm'
import Managers from '../../Request/RequestManagers'
import Modal from '../../Modal'
import SelectRequestType from '../../Select/SelectRequestType'
import SelectVariable from '../SelectVariable'
import { VariableTypes } from '../constants'
import MarketplaceSelect from './MarketplaceSelect'
import { showMarketplace } from '../../../utils/commonSelectors'
import InputField from '../../InputField'
import NewSelectSimple from '../../NewSelectSimple'
import NewSelectAsync from '../../NewSelectAsync'
import LabelsRow from '../../Labels/LabelsRow'
import styles from './Card.module.scss'
import DueDate from './DueDate'
import Radio from '../../Radio'
import RequestProviders from '../../RequestProviders'
import RelatedRequests from '../../Request/RequestInfo/RelatedRequests'
import Warning from '../../Warning'

import { isStaffUser, isDwellerUser, getUserName } from '../../../utils/utils'
import { USER_GROUPS } from '../../../constants'
import { DEADLINE_REGEXP } from './DueDate'
import api from '../../../core/api'

type State = {
  assignees: Array<Object>,
  building?: Object,
  category: ?number,
  contractors?: Array<Object>,
  contractors_option?: string | null,
  editingManagers: boolean,
  errorDuedate: boolean,
  errorText: boolean,
  errorTitle: boolean,
  files: Array<Object>,
  files_options?: Array<string>,
  flat?: Object,
  loading: boolean,
  marketplaceAction?: string,
  marketplaceCc?: Array<string>,
  marketplaceSendMessage?: boolean,
  marketplaceText?: string,
  marketplaceTitle?: string,
  marketplaceVariable: ?string,
  message: string,
  onFilesUploading?: Function,
  onFinishedFilesUploading?: Function,
  owner?: Object,
  popupWasClosed: boolean,
  related_requests?: Array<Object>,
  related_requests_option?: string | null,
  status: Object,
  title: string,
  usersCount?: number,
  variable: ?string,
}
type Props = {
  active: boolean,
  automate?: boolean,
  canSaveOnUnmount: () => boolean,
  data: Object,
  isMarketplaceEnabled: boolean,
  onClickOut: () => void,
  onUpdate: Object => void,
  statuses: Array<Object>,
  t: string => string,
}

class Request extends Component<Props, State> {
  statuses = this.props.statuses.map(s => ({
    value: s.id,
    label: this.props.t(`Common:${s.title}`),
    css: s.css,
  }))

  state = {
    assignees: [],
    category: null,
    contractors: [],
    editingManagers: false,
    errorText: false,
    errorTitle: false,
    errorDueDate: false,
    files: [],
    files_options: [],
    labels: [],
    due_date_interval: '',
    message: '',
    popupWasClosed: false,
    status: null,
    title: '',
    variable: null,
    marketplaceAction: 'noop',
    marketplaceCc: [],
    marketplaceTitle: '',
    marketplaceText: '',
    marketplaceVariable: null,
    marketplaceSendMessage: false,
    loading: true,
    activeIndex: null,
    building: null,
    flat: null,
    owner: null,
    contractors_option: null,
    related_requests: [],
    related_requests_option: null,
    usersCount: 0,
  }

  componentDidMount() {
    const {
      due_date_interval,
      assignees,
      category,
      files,
      text,
      status,
      title,
      variable,
      labels,
      owner,
      flat,
      building,
      contractors,
      related_requests,
      contractors_option,
      related_requests_option,
      files_options,
      marketplace_action: marketplaceAction,
      marketplace_cc: marketplaceCc,
      marketplace_title: marketplaceTitle,
      marketplace_text: marketplaceText,
      marketplace_send_message: marketplaceSendMessage,
      marketplace_variable: marketplaceVariable,
    } = this.props.data

    this.setState({
      due_date_interval,
      assignees: this.props.automate
        ? assignees
        : assignees.map(assignee => ({
            ...assignee,
            id: assignee.owner,
          })),
      category,
      files,
      labels,
      message: text,
      status: this.statuses.find(s => s.value === status),
      title,
      variable,
      marketplaceAction,
      marketplaceCc,
      marketplaceTitle,
      marketplaceText,
      marketplaceSendMessage,
      marketplaceVariable,
      loading: false,
      modal: null,
      owner: this.isSystemOwner(owner)
        ? this.getSystemOwners().find(o => o.id === owner.id)
        : owner,
      flat: this.isSystemFlat(flat)
        ? this.getSystemFlats().find(f => f.id === flat.id)
        : flat,
      building: this.isSystemBuilding(building)
        ? this.getSystemBuildings().find(b => b.id === building.id)
        : building,
      contractors,
      related_requests,
      contractors_option,
      related_requests_option,
      files_options,
    })
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { files } = this.state
    const { active } = prevProps

    if (files.length !== prevState.files.length) {
      this.update()
    }

    if (active && !this.props.active) {
      this.update()
    }
  }

  componentWillUnmount() {
    if (this.props.canSaveOnUnmount()) {
      this.update()
    }
  }

  handleClickOut = () => {
    const { popupWasClosed, editingManagers } = this.state

    if (popupWasClosed) {
      this.setState({ popupWasClosed: false })
    } else if (!editingManagers && this.props.onClickOut) {
      this.props.onClickOut()
    }
  }

  updateField = val => opt => {
    this.setState(
      () => ({
        [val]: opt,
      }),
      this.props.automate ? this.update : null
    )
  }

  updateAssignees = value => this.updateField('assignees')(value.users)

  updateLabels = value =>
    this.updateField('labels')(value.filter(i => !!i.checked))

  updateForm = form => {
    this.setState(
      state => ({
        ...form,
        errorText: form.message ? false : state.errorText,
        errorTitle: form.title ? false : state.errorTitle,
      }),
      this.props.automate ? this.update : () => {}
    )
  }

  updateMarketPlaceForm = form => {
    const { cc, message, title } = form

    const getFormattedCC = cc => {
      if (!cc || !cc.length) {
        return []
      }

      return cc
    }

    this.setState(state => ({
      ...state,
      marketplaceCc: getFormattedCC(cc),
      marketplaceText: message,
      marketplaceTitle: title,
    }))
  }

  updateContractorsOption = e =>
    this.updateField('contractors_option')(e.currentTarget.value)

  update = () => {
    if (this.validate()) {
      const { data, onUpdate, automate } = this.props
      const {
        due_date_interval,
        assignees,
        category,
        message,
        labels,
        status,
        title,
        files,
        files_options,
        variable,
        marketplaceAction,
        marketplaceCc,
        marketplaceTitle,
        marketplaceVariable,
        marketplaceText,
        marketplaceSendMessage,
        building,
        flat,
        owner,
        contractors,
        contractors_option,
        related_requests,
        related_requests_option,
      } = this.state

      const isCategoryId = Number.isInteger(category)
      let categoryId = null

      const getEmails = list =>
        JSON.stringify(
          list.map(i => (i.profile ? i.profile.email : i.name ? i.name : i))
        )

      if (isCategoryId) {
        categoryId = category
      } else if (category) {
        categoryId = category.id
      }

      const ownerAddressFields = automate ? { building, flat, owner } : {}
      const contractorsFields = automate
        ? { contractors, contractors_option }
        : {}
      const relatedFields = automate
        ? { related_requests, related_requests_option }
        : {}
      const filesOptions = automate ? { files_options } : {}

      onUpdate({
        id: data.id,
        text: message,
        title,
        due_date_interval: due_date_interval,
        labels: JSON.stringify(labels.map(i => i.id)),
        marketplace_cc: getEmails(marketplaceCc || []),
        marketplace_title: marketplaceTitle,
        marketplace_variable: marketplaceVariable || '',
        marketplace_text: marketplaceText,
        marketplace_action: marketplaceAction,
        marketplace_send_message: marketplaceSendMessage,
        assignees: JSON.stringify(assignees.map(a => a.id || a.owner)),
        category: categoryId,
        status: status ? status.value : null,
        files: JSON.stringify(files.map(f => f.id)),
        variable: variable || '',
        ...ownerAddressFields,
        ...contractorsFields,
        ...relatedFields,
        ...filesOptions,
      })
    }
  }

  changeSelectedUsers = user => {
    const { assignees } = this.state
    let newAssignees = []

    if (Array.isArray(assignees)) {
      if (assignees.map(a => a.id).includes(user.id)) {
        newAssignees = assignees.filter(a => a.id !== user.id)
      } else {
        newAssignees = assignees.concat(user)
      }
    }

    this.updateField('assignees')(newAssignees)
  }

  changeFilesOptions = option => {
    const { files_options } = this.state
    let newOptions = []

    if (files_options.includes(option)) {
      newOptions = files_options.filter(o => o !== option)
    } else {
      newOptions = files_options.concat(option)
    }

    this.updateField('files_options')(newOptions)
  }

  getFilesOptions = () => [
    { value: 'activity_files', label: this.props.t('Automate:FromActivity') },
    {
      value: 'request_files',
      label: this.props.t('Automate:FromServiceRequestProfile'),
    },
  ]

  getSystemOwners = params =>
    params?.page > 1 ||
    params?.building > 0 ||
    params?.flat > 0 ||
    params?.fullname?.length >= 3
      ? []
      : [
          { id: 'request_owner', fullname: this.props.t('request_owner') },
          {
            id: 'trigger_initiator',
            fullname: this.props.t('trigger_initiator'),
          },
          {
            id: 'automation_executor',
            fullname: this.props.t('automation_executor'),
          },
        ]

  getSystemBuildings = params =>
    params?.page > 1 ||
    params?.owner > 0 ||
    params?.dweller > 0 ||
    params?.search?.length >= 3
      ? []
      : [{ id: 'request_building', address: this.props.t('request_building') }]

  getSystemFlats = params =>
    params?.page > 1 ||
    params?.owner > 0 ||
    params?.building > 0 ||
    params?.inumber_contains?.length > 0
      ? []
      : [{ id: 'request_flat', number: this.props.t('request_flat') }]

  getSystemManagers = params =>
    params?.page > 1 || params?.fullname?.length >= 3
      ? []
      : [
          {
            id: 'request_assignees',
            fullname: this.props.t('request_assignees'),
          },
        ]

  isSystemOwner = owner =>
    ['request_owner', 'trigger_initiator', 'automation_executor'].includes(
      owner?.id
    )
  isSystemBuilding = building => building?.id === 'request_building'
  isSystemFlat = flat => flat?.id === 'request_flat'

  ownerApi = params =>
    Promise.all([
      this.getSystemOwners(params),
      this.isSystemBuilding(this.state.building) ||
      this.isSystemFlat(this.state.flat)
        ? () => []
        : api.profile.getDwellerOptionList(params),
    ]).then(data => {
      const res = {
        results: {
          objects: [...data[0], ...(data[1].results?.objects || [])],
        },
        meta: {
          ...(data[1].meta || {}),
          count: data[1].meta?.count + data[0].length,
        },
      }

      return res
    })

  buildingApi = params =>
    Promise.all([
      this.getSystemBuildings(params),
      this.isSystemOwner(this.state.owner) || this.isSystemFlat(this.state.flat)
        ? () => []
        : api.building.getBuildingOptions(params),
    ]).then(data => {
      const res = {
        results: {
          objects: [...data[0], ...(data[1].results?.objects || [])],
        },
        meta: {
          ...(data[1].meta || {}),
          count: data[1].meta?.count + data[0].length,
        },
      }

      return res
    })

  flatApi = params =>
    Promise.all([
      this.getSystemFlats(params),
      this.isSystemBuilding(this.state.building) ||
      this.isSystemOwner(this.state.owner)
        ? () => []
        : api.flat.getListTiny(params),
    ]).then(data => {
      const res = {
        results: {
          objects: [...data[0], ...(data[1].results?.objects || [])],
        },
        meta: {
          ...(data[1].meta || {}),
          count: data[1].meta?.count + data[0].length,
        },
      }

      return res
    })

  managerApi = params =>
    Promise.all([
      this.getSystemManagers(params),
      api.profile.getAdminAndManagerOptionList(params),
    ]).then(data => {
      const res = {
        results: {
          objects: [...data[0], ...(data[1].results?.objects || [])],
        },
        meta: {
          ...(data[1].meta || {}),
          count: data[1].meta?.count + data[0].length,
        },
      }

      return res
    })

  setUsersCount = count => this.setState({ usersCount: count })

  hideManagersPopup = () => {
    this.setState({
      editingManagers: false,
      popupWasClosed: true,
    })
  }

  showManagersPopup = () => {
    this.setState({ editingManagers: true })
  }

  setModal = modal => {
    this.setState({ modal: modal })
  }

  validate = () => {
    const { message, title, due_date_interval } = this.state
    const { automate } = this.props
    let error = false

    if (!message) {
      this.setState({ errorText: true })
      error = true
    }

    if (!title) {
      this.setState({ errorTitle: true })
      error = true
    }

    if (automate) {
      const validDueDate =
        !due_date_interval || DEADLINE_REGEXP.test(due_date_interval)
      this.setState({ errorDueDate: !validDueDate })
      error = !validDueDate
    }

    return !error
  }

  renderFileVariable = () => {
    const { t } = this.props
    const { variable } = this.state

    return (
      <div className='request-constructor__card--request-documents'>
        <div className='request-constructor__card--request-item-title'>
          {t('documents')}:
        </div>
        <SelectVariable
          clearable
          include={[VariableTypes.file]}
          placeholder={t('selectFileVariable')}
          value={variable}
          onChange={val => this.updateField('variable')(val.value)}
        />
      </div>
    )
  }

  renderMpFileVariable = () => {
    const { t } = this.props
    const { marketplaceVariable } = this.state

    return (
      <div className='request-constructor__card--request-documents'>
        <div className='request-constructor__card--request-item-title'>
          {t('documents')}:
        </div>
        <SelectVariable
          clearable
          include={[VariableTypes.file]}
          placeholder={t('selectFileVariable')}
          value={marketplaceVariable}
          onChange={val => this.updateField('marketplaceVariable')(val.value)}
        />
      </div>
    )
  }

  changeMessageSending = e => {
    this.setState({ marketplaceSendMessage: e.target.checked })
  }

  setViewFiles = ind => {
    const { setFileIndex, setFiles } = this.props
    setFileIndex(ind)
    setFiles([...this.state.files])
  }

  render() {
    const { data, isMarketplaceEnabled, t, automate } = this.props
    const {
      status,
      labels,
      category,
      assignees,
      due_date_interval,
      title,
      message,
      files,
      files_options,
      errorTitle,
      errorText,
      errorDueDate,
      editingManagers,
      marketplaceAction,
      marketplaceCc,
      marketplaceTitle,
      marketplaceText,
      marketplaceSendMessage,
      loading,
      modal,
      owner,
      building,
      flat,
      contractors_option,
      usersCount,
      contractors,
      related_requests,
      related_requests_option,
    } = this.state

    if (loading) {
      return null
    }

    const cardClass = classnames(
      'request-constructor__card--body request-constructor__card--request',
      {
        'request-constructor__card--request--error-title': errorTitle,
        'request-constructor__card--request--error-text': errorText,
      }
    )

    const rbId = uuid()

    return (
      <>
        <OutsideClick onClickOut={this.handleClickOut}>
          <div className={cardClass}>
            <div>{t('requestDescription')}</div>
            {automate && (
              <Warning
                noArrow
                text={t('AutomationArchivedHint')}
                boxClassName={styles.warningBox}
                className={styles.warning}
              />
            )}
            <div className='request-constructor__card--request-item-title'>
              {t('DueDate')}:
            </div>
            {automate ? (
              <InputField
                style={{ marginBottom: '10px' }}
                error={errorDueDate && t('WrongDeadlineFormat')}
                name={'deadline_template'}
                value={due_date_interval}
                placeholder='4d 6h 45m'
                onChange={e =>
                  this.updateField('due_date_interval')(e.target.value)
                }
              />
            ) : (
              <DueDate
                dueDate={due_date_interval}
                onChange={this.updateField('due_date_interval')}
              />
            )}
            <div className='request-constructor__card--request-item-title'>
              {t('selectCategory')}:
            </div>
            <SelectRequestType
              value={category}
              onChange={this.updateField('category')}
            />
            <div className='request-constructor__card--request-item-title'>
              {t('selectStatus')}:
            </div>
            <NewSelectSimple
              style={{ zIndex: 2 }}
              options={this.statuses}
              placeholder={t('selectStatus')}
              value={status}
              onChange={this.updateField('status')}
            />
            {automate && (
              <>
                <div className='request-constructor__card--request-item-title'>
                  {t('selectInitiator')}:
                </div>
                <NewSelectAsync
                  style={{ width: '100%' }}
                  pageSize={10}
                  api={this.ownerApi}
                  searchKey='fullname'
                  placeholder={t('Select:Placeholder')}
                  getLabel={getUserName}
                  getSelectedLabel={getUserName}
                  view='dropdown'
                  selectedItems={owner ? [owner] : []}
                  dynamicParams={{
                    building: building?.id,
                    flat: flat?.id,
                  }}
                  onClick={owner => this.updateField('owner')(owner)}
                  onClear={() => this.updateField('owner')(null)}
                />
                <div className='request-constructor__card--request-item-title'>
                  {t('selectBuilding')}:
                </div>
                <NewSelectAsync
                  style={{ width: '100%' }}
                  pageSize={10}
                  delay={700}
                  view='dropdown'
                  searchKey='search'
                  api={this.buildingApi}
                  getLabel={building =>
                    building.address_obj?.value || building.address
                  }
                  getSelectedLabel={building =>
                    building?.address_obj?.value || building.address
                  }
                  placeholder={t('Select:selectAddress')}
                  selectedItems={building ? [building] : []}
                  dynamicParams={Object.assign(
                    {},
                    { is_promo: 0 },
                    owner && isStaffUser(owner)
                      ? undefined
                      : {
                          is_promo: 0,
                          owner:
                            owner && isDwellerUser(owner) ? null : owner?.id,
                          dweller:
                            owner && isDwellerUser(owner) ? owner?.id : null,
                        }
                  )}
                  onClear={() => {
                    this.updateField('building')(null)
                    this.updateField('flat')(null)
                  }}
                  onClick={building => {
                    this.updateField('building')(building)
                    this.updateField('flat')(null)
                  }}
                />
                <div className='request-constructor__card--request-item-title'>
                  {t('selectFlat')}:
                </div>
                <NewSelectAsync
                  style={{ width: '100%' }}
                  delay={700}
                  pageSize={10}
                  view='dropdown'
                  searchKey='number_icontains'
                  api={this.flatApi}
                  disabled={!building}
                  minSearchLength={1}
                  getLabel={flat => flat.number}
                  getSelectedLabel={flat => flat.number}
                  placeholder={t('Select:selectFlat')}
                  selectedItems={flat ? [flat] : []}
                  dynamicParams={{
                    building: building?.id,
                    owner: owner?.id,
                  }}
                  onClear={() => {
                    this.updateField('flat')(null)
                  }}
                  onClick={flat => this.updateField('flat')(flat)}
                />
              </>
            )}
            <div className='request-constructor__card--request-item-title'>
              {t('selectManagers')}:
              {!automate && <Button.Edit onClick={this.showManagersPopup} />}
            </div>
            {!automate && <Managers items={assignees} />}
            {automate && (
              <NewSelectAsync
                isMulti
                noAll
                style={{ width: '100%' }}
                placeholder={t('Select:Placeholder')}
                api={this.managerApi}
                permanentParams={{
                  group: [USER_GROUPS.admin, USER_GROUPS.manager],
                }}
                pageSize={10}
                view='dropdown'
                searchKey='fullname'
                selectedItems={assignees ? assignees : []}
                getLabel={getUserName}
                getSelectedLabel={getUserName}
                isAllSelected={selected => usersCount === selected.length}
                setMetaCount={this.setUsersCount}
                onClick={this.changeSelectedUsers}
              />
            )}
            <LabelsRow
              infoData={{
                ...this.props.data,
                label_objs: labels,
              }}
              className={styles.label}
              value={labels}
              permissions={{ can_edit_labels: true }}
              onEdit={this.setModal}
              onChange={this.updateLabels}
            />
            {automate && (
              <RelatedRequests
                editing
                automate
                relatedRequests={related_requests}
                relateToCurrent={related_requests_option === 'request'}
                onEdit={this.setModal}
                onChange={data => {
                  this.updateField('related_requests')(data.relatedRequests)
                  this.updateField('related_requests_option')(
                    data.relateToCurrent ? 'request' : null
                  )
                }}
              />
            )}
            <MailReplyForm
              creating
              isToHided
              showAddButton
              onlyManualFileRemove
              automationRequest={automate}
              noFileLimit={automate}
              uuid={`card-${data.id}`}
              renderAdditional={!automate && this.renderFileVariable}
              values={{ title, message, files }}
              setFileIndex={this.setViewFiles}
              onUpdate={this.updateForm}
              onWysiwygFocus={this.props.onWysiwygFocus}
              onFilesUploading={this.props.onFilesUploading}
              onFinishedFilesUploading={this.props.onFinishedFilesUploading}
            />
            {isMarketplaceEnabled && !automate && (
              <MarketplaceSelect
                value={marketplaceAction}
                marketplaceCc={marketplaceCc}
                marketplaceTitle={marketplaceTitle}
                marketplaceText={marketplaceText}
                renderAdditional={this.renderMpFileVariable}
                marketplaceSendMessage={marketplaceSendMessage}
                changeMessageSending={this.changeMessageSending}
                id={data.id}
                updateMarketPlaceForm={this.updateMarketPlaceForm}
                onWysiwygFocus={this.props.onWysiwygFocus}
                onChange={option =>
                  this.updateField('marketplaceAction')(option.value)
                }
              />
            )}
            {automate && (
              <>
                <div className='request-constructor__card--request-item-title'>
                  {t('Automate:AddFiles')}:
                </div>
                <NewSelectSimple
                  isMulti
                  options={this.getFilesOptions()}
                  placeholder={this.props.t('Automate:NoAttach')}
                  selectedItems={files_options}
                  getSelectedLabel={option =>
                    this.getFilesOptions().find(o => o.value === option)?.label
                  }
                  setSelectedItems={option =>
                    this.changeFilesOptions(option.value)
                  }
                  onChange={() => {}}
                />
              </>
            )}
            {automate && (
              <>
                <div
                  style={{
                    margin: '10px 0 ',
                    height: '120px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Radio
                    id={`contractors_null_${rbId}`}
                    name={'contractors'}
                    value={'not_assign'}
                    checked={contractors_option === 'not_assign'}
                    label={this.props.t('ContractorsNotAssign')}
                    onChange={this.updateContractorsOption}
                  />
                  <Radio
                    id={`contractors_request_${rbId}`}
                    name={'contractors'}
                    value={'request_contractors'}
                    checked={contractors_option === 'request_contractors'}
                    label={this.props.t('ContractorsFromRequest')}
                    onChange={this.updateContractorsOption}
                  />
                  <Radio
                    id={`contractors_mapping_${rbId}`}
                    name={'contractors'}
                    value={'mapping_based_contractors'}
                    checked={contractors_option === 'mapping_based_contractors'}
                    label={this.props.t('ContractorsMappingBased')}
                    onChange={this.updateContractorsOption}
                  />
                  <Radio
                    id={`contractors_custom_${rbId}`}
                    name={'contractors'}
                    value={'custom'}
                    checked={contractors_option === 'custom'}
                    label={this.props.t('ContractorsSelect')}
                    onChange={this.updateContractorsOption}
                  />
                </div>
                {contractors_option === 'custom' && (
                  <RequestProviders
                    automate
                    contractors={contractors}
                    buildingId={building}
                    onChange={() => {}}
                    onChangeContractors={value =>
                      this.updateField('contractors')(value)
                    }
                  />
                )}
              </>
            )}
          </div>
          <Modal
            isOpen={editingManagers}
            onRequestClose={this.hideManagersPopup}
          >
            <EditManagersPopup
              onlyManagers
              profileCheck
              title={t('editManagersTitle')}
              data={assignees}
              okText={t('Common:Save')}
              onClose={this.hideManagersPopup}
              onSave={this.updateAssignees}
            />
          </Modal>
          <Modal
            isOpen={!!modal}
            style={{ content: { minWidth: '482px' } }}
            onRequestClose={() => {
              this.setModal(null)
            }}
          >
            {modal}
          </Modal>
        </OutsideClick>
      </>
    )
  }
}

const mapStateToProps = state => ({
  statuses: state.init.request_status,
  isMarketplaceEnabled: showMarketplace(state),
})

export default compose(
  withTranslation('RequestConstructor'),
  connect(mapStateToProps)
)(Request)
