import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDrag, useDrop } from 'react-dnd'

import NewTableBodyRow from '../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../NewTable/NewTableBodyCell'

import { updateAccountOrdering } from '../../core/api/api.mail'

const AccountItem = props => {
  const {
    account: { id, email, corporate, position },
  } = props

  const { t } = useTranslation('EmailIntegrationNylas')

  const ref = useRef(null)

  const getAccountType = corporate =>
    corporate ? t('Corporate') : t('Private')

  const [, drag] = useDrag({
    item: {
      id,
      type: 'account',
      name,
      position,
    },
    canDrag: () => true,
    collect: monitor => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  })

  const [, drop] = useDrop({
    accept: 'account',
    canDrop: (dropProps, monitor) => {
      const { id: targetId } = monitor.getItem()

      return targetId !== id
    },
    drop: (dropProps, monitor) => {
      const { id: targetId } = monitor.getItem()

      if (id === targetId) {
        return
      }

      updateAccountOrdering(targetId, { position }).finally(() => {
        props.setLoading(true)
      })
    },
    collect: monitor => ({
      isDropTarget: monitor.canDrop() && monitor.isOver({ shallow: true }),
    }),
  })

  drag(drop(ref))

  return (
    <NewTableBodyRow ref={ref}>
      <NewTableBodyCell title={email} />
      <NewTableBodyCell title={getAccountType(corporate)} />
    </NewTableBodyRow>
  )
}

export default AccountItem
