// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import RouterTabs from '../Tabs/RouterTabs'
import Pan from '../Tabs/Pan'
import Integrations from './Integrations'
import Accounts from './Accounts'

const EmailIntegrationNew = (): Node => {
  const { t } = useTranslation('EmailIntegrationNylas')

  const [count, setCount] = useState(null)

  return (
    <div className='content__col' style={{ paddingTop: '20px' }}>
      <section className='settings-rights unit unit--default'>
        <div className='bar__title-indented bar__row'>
          <div className='bar__title-subtitle'>
            <span className='bar__title-text'>
              {t('EmailIntegrationTitle')}
            </span>
          </div>
        </div>
        <RouterTabs count={count}>
          <Pan label={t('AllAccounts')} linkHash='#accounts'>
            <Accounts key='accounts' setCount={setCount} />
          </Pan>
          <Pan label={t('MyIntegrations')} linkHash='#integrations'>
            <Integrations key='integrations' setCount={setCount} />
          </Pan>
        </RouterTabs>
      </section>
    </div>
  )
}

export default EmailIntegrationNew
