// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import type { Node } from 'react'

import NewTableBody from '../../NewTable/NewTableBody'
import EmailIntegrationTableBodyRow from './EmailIntegrationTableBodyRow'

type Props = {
  emails: Array<Object>,
}

const EmailIntegrationTableBody = (props: Props): Node => {
  const { emails } = props
  const dispatch = useDispatch()

  const handleOpen = id => {
    dispatch(push(`/settings/email-integration/integrations/${id}`))
  }

  return (
    <NewTableBody>
      {emails.map(email => (
        <EmailIntegrationTableBodyRow
          key={email.id}
          email={email}
          handleOpen={handleOpen}
          {...props}
        />
      ))}
    </NewTableBody>
  )
}

export default EmailIntegrationTableBody
